import { Box, CircularProgress, Container, Divider, Grid, Icon, IconButton, Menu, MenuItem, Modal, Slide, Table, TableContainer, TextField } from "@mui/material";
import { useAuth } from "AuthProvider";
import axios from "axios";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import dayjs from "dayjs";
import useDataFetching from "hooks/useDataFetching";
import usePostWithFile from "hooks/usePostWithFile";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Permit({ children, value, index, ...other }) {
    const { t } = useTranslation();
    console.log(children)

    const [motif, setMotif] = useState("");
    const [description, setDescription] = useState("");
    const [requestType, setRequestType] = useState("");
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false);
    const [permit, setPermit] = useState("");
    const [profession, setProfession] = useState("");
    const [salary, setSalary] = useState(0);
    const [signdate, setDate] = useState("");
    const [indLogement, setIndLogement] = useState(0);
    const [indTransport, setIndTransport] = useState(0);
    const [indResponsabilite, setIndResponsabilite] = useState(0);
    const [indRepresentation, setIndRepresentation] = useState(0);
    const [primeExploitation, setPrimeExploitation] = useState(0);
    const [sursalaire, setSursalaire] = useState(0);
    const [primeSalissure, setPrimeSalissure] = useState(0);
    const [indCaisse, setIndCaisse] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [primePanier, setPrimePanier] = useState(0);
    const [billetAvion, setBilletAvion] = useState(0);
    const [autresPrimes, setAutresPrimes] = useState(0);
    const [employe, setEmploye] = useState(null);
    const [contractId, setContractId] = useState(null);
    const [errors, setErrors] = useState({});
    const { user, token } = useAuth();
    const [employees, setEmployees] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [postDataWithFile, loadF, errorF, dataFile] = usePostWithFile(`${config.app.api_url}/contracts/declarations`);
    const [postDataWithFileR, loadR, errorR, dataR] = usePostWithFile(`${config.app.api_url}/user-requests`);

    // FETCH OCCUPATIONS LIST
    const [loadingP, errorP, occupations, fetchOccupations] = useDataFetching(
        `${config.app.api_url}/occupations`
    );
    console.log("Error ", errorP, errorF, contracts, errorR)

    useEffect(() => {
        fetchOccupations();
    }, []);

    useEffect(() => {
        if (user) {
            showEmployees(user);
            showPermits(user);
        }
    }, [user]);

    const [loading, setLoading] = useState(false);

    //FETCH EMPLOYEES BY ENTERPRISE ID
    const showPermits = (user) => {
        if (!user) return
        setLoading(true);
        setContracts([]);
        axios
            .get(`${config.app.api_url}/enterprises/${user?.myEnterprise?.id}/contracts`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                console.log("CONTRACTS ", res);
                setContracts(res?.data?.data);
            })
            .catch((err) => {
                console.log("EMPLOYEES ERR ", err);
                setContracts([]);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    //FETCH EMPLOYEES BY ENTERPRISE ID
    const showEmployees = (user) => {
        if (!user) return
        setLoading(true);
        setEmployees([]);
        axios
            .get(`${config.app.api_url}/enterprises/${user?.myEnterprise?.id}/employers`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                console.log("EMPLOYEES", res);
                setEmployees(res?.data?.data);
            })
            .catch((err) => {
                console.log("EMPLOYEES ERR ", err);
                setEmployees([]);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!employe) newErrors.employe = t("Declaration.Employees.form.errors.employe");
        if (!permit) newErrors.permit = t("Declaration.Employees.form.errors.permit");
        if (!salary) newErrors.salary = t("Declaration.Employees.form.errors.salary");
        if (!signdate) newErrors.signdate = t("Declaration.Employees.form.errors.signdate");
        if (!contract) newErrors.contract = t("Declaration.Employees.form.errors.contract");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            const formData = new FormData();
            formData.append('contractFile', contract);
            formData.append('salary', salary);
            formData.append('permitNumber', permit);
            formData.append('permitDate', signdate);
            formData.append('occupationName', profession);
            formData.append('indLogement', indLogement);
            formData.append('indTransport', indTransport);
            formData.append('indResponsabilite', indResponsabilite);
            formData.append('indRepresentation', indRepresentation);
            formData.append('primeExploitation', primeExploitation);
            formData.append('sursalaire', sursalaire);
            formData.append('primeSalissure', primeSalissure);
            formData.append('indCaisse', indCaisse);
            formData.append('bonus', bonus);
            formData.append('primePanier', primePanier);
            formData.append('billetAvion', billetAvion);
            formData.append('autresPrimes', autresPrimes);
            formData.append('bonus', bonus);
            formData.append('bonus', bonus);
            formData.append('employerId', employe);

            await postDataWithFile(formData);
        }
    };

    const handleSubmitRequest = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!motif) newErrors.motif = t("Declaration.Employees.form.errors.motif");
        if (!description) newErrors.description = t("Declaration.Employees.form.errors.description");
        if (!requestType) newErrors.requestType = t("Declaration.Employees.form.errors.requestType");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            // Envoi du fichier avec le FormData
            const formData = new FormData();
            formData.append('userId', user?.id);
            formData.append('targetedContractId', contractId?.id);
            formData.append('enterpriseId', user?.myEnterprise?.id);
            formData.append('motif', motif);
            formData.append('description', description);
            formData.append('requestEntityType', "CONTRACT");
            formData.append('requestType', requestType);
            formData.append("attachedFiles", attachedFiles);

            await postDataWithFileR(formData);
        }
    };

    useEffect(() => {
        console.log("DATA ", dataFile)
        if (dataFile && dataFile?.data?.id) {
            toast(dataFile?.message || t("success"))
        } else {
            // setErrorData(data?.message)
            toast(dataFile?.message || t("error"))
        }
    }, [dataFile])

    useEffect(() => {
        console.log("DATA ", dataR)
        if (dataR && dataR?.data?.id) {
            toast(dataR?.message || t("success"))
            setMotif("");
            setDescription("");
        } else {
            // setErrorData(data?.message)
            toast(dataR?.message || t("error"))
        }
    }, [dataR])


    //FORMATAGE DES SALAIRES
    const formatNumber = (nombre) => {

        if (nombre != null && nombre != undefined) {
            return nombre.toLocaleString('us')
        }
        else {
            return '--'
        }
    }


    function getExpiredPermitDate(permitDate) {
        if (!permitDate) return null; // Vérifie si permitDate est fourni
        const date = new Date(permitDate); // Crée une seule fois l'objet Date
        date.setFullYear(date.getFullYear() + 2); // Ajoute 2 ans à l'année actuelle
        return date;
    }

    const [errorFile, setErrorFile] = useState("");
    const [errorFiles, setErrorFiles] = useState("");
    const [contract, setContract] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.pdf"))
            } else if (file.size > maxSize) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.file"))
            } else {
                setErrorFile("")
                console.log("File ", file)
                setContract(file); // Si tout est correct, enregistrer le fichier
            }
        }
    };

    const handleFilesChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFiles(t("Register.errors.pdf"))
            } else if (file.size > maxSize) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.file"))
            } else {
                setErrorFiles("")
                console.log("File ", file)
                setAttachedFiles(file); // Si tout est correct, enregistrer le fichier
            }
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <ToastContainer />
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Container>
                        <Grid
                            container
                            item
                            xs={7}
                            lg={12}
                            md={7}
                            justifyContent="space-between" // Gérer l'espacement entre le texte et le bouton
                            alignItems="flex-end" // Aligner le bouton au fond (bas) à droite
                        >
                            <MKTypography
                                variant="h3"
                                color="black"
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '50%',
                                        height: '3px',
                                        backgroundColor: '#FCE410',
                                        transform: 'translateX(-200%)',
                                    },
                                }}
                                mr={5}
                            >
                                {t("Declaration.Permit.list")} ({!loading && contracts ? contracts?.content.length : 0})
                            </MKTypography>

                            <MKButton
                                variant="contained"
                                color="primary"
                                size="sm"
                                component="a"
                                onClick={() => setShow(true)}
                                sx={{ height: 'fit-content' }}
                            >
                                {t("Declaration.Permit.add")}
                            </MKButton>
                        </Grid>

                    </Container>
                    <TableContainer>
                        {/* <Container>
                            <Grid container item justifyContent="center" alignItems={"center"} lg={12}>
                                {enterprises?.content && <TablePagination
                                    component="div"
                                    count={enterprises?.totalElements}
                                    page={page}
                                    onRowsPerPageChange={(event) => {
                                        // event.preventDefault();
                                        const value = parseInt(event.target.value, 10);
                                        console.log("VALUE ", value);
                                        setLimit(value);
                                        setPage(0);
                                    }}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={limit}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    labelRowsPerPage={t("numberPage")}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                                    lang={i18n.language}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                />}
                            </Grid>
                        </Container> */}
                        <Table sx={{ mt: 3 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>#</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("FNTEC.table.employes.noms")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Permit.table.salary")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Permit.table.numpermis")}</th>
                                    {/* <th>Entreprise</th> */}
                                    {/* <th>Salaire</th> */}
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Permit.table.signdate")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Permit.table.expdate")}</th>
                                    {/* <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Permit.table.duree")}</th> */}
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("FNTEC.table.employes.statut")}</th>
                                </tr>
                            </thead>
                            {loading && <CircularProgress />}
                            {!loading && <tbody>
                                {contracts && contracts?.content.map((item, index) => (
                                    <tr style={{
                                        color: "#0F056B",
                                        verticalAlign: "bottom",
                                        fontSize: "12px"
                                    }} key={index}>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                        <td style={{ color: "#0F056B" }}>{item?.employer?.fullName}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{formatNumber(item?.salary)}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{item?.permitNumber}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{dayjs(item?.permitDate).format("DD-MM-YYYY")}</td>
                                        {/* <td>{item?.enterprise?.socialRaison.toUpperCase()}</td> */}
                                        {/* <td>{formatNumber(item?.salary)}</td> */}
                                        <td className="text-center" style={{ color: "#0F056B" }}>{dayjs(getExpiredPermitDate(item?.permitDate)).format("DD-MM-YYYY")}</td>
                                        {/* <td className="text-center"><Icon style={{ color: "#FF0000" }} fontSize="inherit">thumb_down</Icon></td> */}

                                        <td className="text-center" style={{ color: "#0F056B" }}>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                slotProps={{
                                                    paper: {
                                                        style: {
                                                            maxHeight: 48 * 4.5,
                                                            width: '20ch',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem onClick={() => {
                                                    setContractId(item)
                                                    setShows(true)
                                                }}>
                                                    {t("Declaration.Employees.request")}
                                                </MenuItem>
                                            </Menu>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>}
                        </Table>
                        {/* <Container>
                            <Grid container item justifyContent="center" lg={12}>
                                {enterprises?.content && <TablePagination
                                    component="div"
                                    count={enterprises?.totalElements}
                                    page={page}
                                    onRowsPerPageChange={(event) => {
                                        const value = parseInt(event.target.value, 10);
                                        console.log("VALUE ", value);
                                        setLimit(value);
                                        setPage(0);
                                    }}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={limit}
                                    labelRowsPerPage={t("numberPage")}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                                    lang={i18n.language}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />}
                            </Grid>
                        </Container> */}
                    </TableContainer>


                    <Modal open={show} onClose={() => setShow(false)} sx={{ display: "grid", placeItems: "center" }}>
                        <Slide direction="down" in={show} timeout={500}>
                            <MKBox
                                position="relative"
                                width="80%"
                                display="flex"
                                flexDirection="column"
                                borderRadius="xl"
                                bgColor="white"
                                shadow="xl"
                            >
                                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                    <MKTypography variant="h5">{t("Declaration.Permit.addTitle")}</MKTypography>
                                    <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShow(false)}>close</Icon>
                                </MKBox>
                                <Divider sx={{ my: 0 }} />
                                <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                    <form onSubmit={handleSubmit}>
                                        {errorFile && <MKTypography
                                            variant="subtitle2"
                                            color="black"
                                            style={{ background: "yellow", mt: 2 }}
                                        >
                                            {errorFile}
                                        </MKTypography>}
                                        <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}
                                            {!loading && <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                <TextField
                                                    required
                                                    label={t("Declaration.Permit.choose")}
                                                    id="outlined-required"
                                                    select
                                                    sx={{ width: "100%" }}
                                                    value={employe}
                                                    onChange={(e) => setEmploye(e.target.value)}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                // InputProps={{
                                                //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                // }}
                                                >
                                                    <option>

                                                    </option>
                                                    {employees && employees?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {(option?.fullName?.toUpperCase() || option?.firstName?.toUpperCase() + " " + option?.lastName?.toUpperCase())}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>}
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    // value={contract}
                                                    onChange={handleFileChange}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="file"
                                                    label={t("Declaration.Permit.table.contract")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.contract}
                                                    helperText={errors.contract}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    value={permit}
                                                    onChange={(e) => setPermit(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="text"
                                                    label={t("Declaration.Permit.table.numpermis")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.permit}
                                                    helperText={errors.permit}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    value={signdate}
                                                    onChange={(e) => setDate(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="date"
                                                    label={t("Declaration.Permit.table.signdate")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.signdate}
                                                    helperText={errors.signdate}
                                                />
                                            </Grid>
                                            {!loadingP &&
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        label={t("Declaration.Employees.form.profession")}
                                                        id="outlined-required"
                                                        select
                                                        sx={{ width: "100%" }}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        value={profession}
                                                        onChange={(e) => {
                                                            setProfession(e.target.value)
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.profession}
                                                        helperText={errors.profession}
                                                    // InputProps={{
                                                    //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                    // }}
                                                    >
                                                        <option>
                                                        </option>
                                                        {occupations?.data && occupations?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                            <option key={option.id} value={option.name}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </Grid>}
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    value={salary}
                                                    onChange={(e) => setSalary(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.salary")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.salary}
                                                    helperText={errors.salary}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={indLogement}
                                                    onChange={(e) => setIndLogement(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.indLogement")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.indLogement}
                                                    helperText={errors.indLogement}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={indTransport}
                                                    onChange={(e) => setIndTransport(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.indTransport")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.indTransport}
                                                    helperText={errors.indTransport}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={indResponsabilite}
                                                    onChange={(e) => setIndResponsabilite(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.indResponsabilite")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.indResponsabilite}
                                                    helperText={errors.indResponsabilite}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={indRepresentation}
                                                    onChange={(e) => setIndRepresentation(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.indRepresentation")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.indRepresentation}
                                                    helperText={errors.indRepresentation}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={primeExploitation}
                                                    onChange={(e) => setPrimeExploitation(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.primeExploitation")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.primeExploitation}
                                                    helperText={errors.primeExploitation}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={sursalaire}
                                                    onChange={(e) => setSursalaire(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.sursalaire")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.sursalaire}
                                                    helperText={errors.sursalaire}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={primeSalissure}
                                                    onChange={(e) => setPrimeSalissure(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.primeSalissure")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.primeSalissure}
                                                    helperText={errors.primeSalissure}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={indCaisse}
                                                    onChange={(e) => setIndCaisse(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.indCaisse")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.indCaisse}
                                                    helperText={errors.indCaisse}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={bonus}
                                                    onChange={(e) => setBonus(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.bonus")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.bonus}
                                                    helperText={errors.bonus}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={primePanier}
                                                    onChange={(e) => setPrimePanier(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.primePanier")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.primePanier}
                                                    helperText={errors.primePanier}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={billetAvion}
                                                    onChange={(e) => setBilletAvion(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.billetAvion")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.billetAvion}
                                                    helperText={errors.billetAvion}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={autresPrimes}
                                                    onChange={(e) => setAutresPrimes(e.target.value)}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Declaration.Permit.table.autresPrimes")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.autresPrimes}
                                                    helperText={errors.autresPrimes}
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Container>
                                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                    <MKBox display="flex" justifyContent="left" p={1.5}>
                                        <MKButton variant="contained" color="primary" onClick={handleSubmit}>
                                            {loadF ? t("Declaration.Employees.loading") : t("Declaration.Employees.add")}
                                        </MKButton>
                                    </MKBox>
                                    <MKBox display="flex" justifyContent="right" p={1.5}>
                                        <MKButton variant="gradient" color="dark" onClick={() => setShow(false)}>
                                            {t("FNTEC.table.enterprise.close")}
                                        </MKButton>
                                    </MKBox>
                                </MKBox>
                            </MKBox>
                        </Slide>
                    </Modal>

                    

                    <Modal open={shows} onClose={() => {
                        setContractId(null)
                        setShows(false)
                    }} sx={{ display: "grid", placeItems: "center" }}>
                        <Slide direction="down" in={shows} timeout={500}>
                            <MKBox
                                position="relative"
                                width="80%"
                                display="flex"
                                flexDirection="column"
                                borderRadius="xl"
                                bgColor="white"
                                shadow="xl"
                            >
                                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                    <MKTypography variant="h5">{t("Declaration.Permit.addRequest")}</MKTypography>
                                    <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                        setContractId(null)
                                        setShows(false)
                                    }}>close</Icon>
                                </MKBox>
                                <Divider sx={{ my: 0 }} />
                                <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                    <form onSubmit={handleSubmitRequest}>
                                        {errorFiles && <MKTypography
                                            variant="subtitle2"
                                            color="black"
                                            style={{ background: "yellow", mt: 2 }}
                                        >
                                            {errorFiles}
                                        </MKTypography>}
                                        <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}

                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    label={t("Declaration.Employees.form.request.requestType")}
                                                    id="outlined-required"
                                                    select
                                                    sx={{ width: "100%" }}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={requestType}
                                                    onChange={(e) => {
                                                        setRequestType(e.target.value)
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.requestType}
                                                    helperText={errors.requestType}
                                                >
                                                    <option value="UPDATE">
                                                        {t("Declaration.Employees.form.request.UPDATE")}
                                                    </option>
                                                    <option value="DELETE">
                                                        {t("Declaration.Employees.form.request.DELETE")}
                                                    </option>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    value={motif}
                                                    onChange={(e) => setMotif(e.target.value)}
                                                    type="text"
                                                    id="outlined-required"
                                                    label={t("Declaration.Employees.form.request.motif")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.motif}
                                                    helperText={errors.motif}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    // value={contract}
                                                    onChange={handleFilesChange}
                                                    placeholder=""
                                                    id="outlined-required"
                                                    type="file"
                                                    label={t("Declaration.Employees.form.request.file")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.attachedFiles}
                                                    helperText={errors.attachedFiles}
                                                    // inputProps={{ multiple: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                <TextField
                                                    required
                                                    sx={{ width: "100%" }}
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    id="outlined-required"
                                                    type="text"
                                                    multiline
                                                    label={t("Declaration.Employees.form.request.description")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.description}
                                                    helperText={errors.description}
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Container>
                                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                    <MKBox display="flex" justifyContent="left" p={1.5}>
                                        <MKButton component="a" disabled={loadR} variant="contained" color="primary" onClick={handleSubmitRequest}>
                                            {loadR ? t("Declaration.Permit.addLoading") : t("Declaration.Permit.addRequest")}
                                        </MKButton>
                                    </MKBox>
                                    <MKBox display="flex" justifyContent="right" p={1.5}>
                                        <MKButton variant="gradient" color="dark" onClick={() => {
                                            showEmployees(user)
                                            setShows(false)
                                        }}>
                                            {t("FNTEC.table.enterprise.close")}
                                        </MKButton>
                                    </MKBox>
                                </MKBox>
                            </MKBox>
                        </Slide>
                    </Modal>
                </Box>
            )}
        </div>
    );
}

// Ajouter la validation des props
Permit.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Permit;