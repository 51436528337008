import { Card, CardContent, Container, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";
// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import minefop from "assets/images/minefop.jpg";
import { useEffect, useState } from "react";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import usePostWithFile from "hooks/usePostWithFile";
import useDataFetching from "hooks/useDataFetching";
import { config } from "constants/config/config";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Register() {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [niu, setNiu] = useState("");
    const [region, setRegion] = useState("");
    const [errorData, setErrorData] = useState("");
    const [errorFile, setErrorFile] = useState("");
    const [attestation, setAttestation] = useState();
    const [errors, setErrors] = useState({});
    const [ postDataWithFile, loading, error, data ] = usePostWithFile(`${config.app.api_url}/auth/register-enterprise-account`);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    // FETCH REGIONS LIST
    const [loadingR, errorR, regions, fetchRegions] = useDataFetching(
        `${config.app.api_url}/regions`
    );

    useEffect(() => {
        fetchRegions();
    }, [])

    console.log("Eror ", errorR)

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.pdf"))
            } else if (file.size > maxSize) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.file"))
            } else {
                setErrorFile("")
                console.log("File ", file)
                setAttestation(file); // Si tout est correct, enregistrer le fichier
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!name) newErrors.name = t("Register.errors.name");
        if (!phone) newErrors.phone = t("Register.errors.phone");
        if (!email) newErrors.email = t("Register.errors.email");
        if (!password) newErrors.password = t("Register.errors.password");
        if (!niu) newErrors.niu = t("Register.errors.niu");
        if (!attestation) newErrors.attestation = t("Register.errors.attestation");
        if (!region) newErrors.region = t("Register.errors.region");
        if (errorFile != "") newErrors.attestation = errorFile;

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            const formData = new FormData();
            formData.append('file', attestation);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('niu', niu);
            formData.append('regionId', region);
            formData.append('enterpriseName', name);
            formData.append('firstname', name);
            formData.append('lastname', name);
            formData.append('phone', phone);

            // Envoi du fichier avec le FormData
            await postDataWithFile(formData);
        }
    };

    useEffect(() => {
        console.log("DATA ", data)
        if (data && (data?.status == 200 || data?.status == 201)) {
            if (data && data?.access_token) {
                navigate("/account/verification", { state: { data, mail: email } })
            }
        } else {
            console.log("DATA ERR ", data)
            setErrorData(data?.message)
        }
    }, [data])

    return (
        <MKBox sx={{ background: "#fff" }}>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left", mt: 10 }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Register.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <Grid className="d-flex justify-content-center">
                    <img
                        className="d-block w-20"
                        src={minefop}
                        style={{ height: '30vh' }}
                    />
                </Grid>
                <Grid className="d-flex justify-content-center">
                    <Card style={{ background: "rgba(8, 56, 4, 0.72)", width: "150vh" }}>
                        <CardContent style={{ textAlign: "center" }}>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("Register.card.title")}
                            </MKTypography>
                            {error && <MKTypography
                                variant="subtitle2"
                                color="white"
                                style={{ background: "red", mt: 2 }}
                            >
                                {error}
                            </MKTypography>}
                            {errorData && <MKTypography
                                variant="subtitle2"
                                color="white"
                                style={{ background: "red", mt: 2 }}
                            >
                                {errorData}
                            </MKTypography>}
                            {errorFile && <MKTypography
                                variant="subtitle2"
                                color="black"
                                style={{ background: "yellow", mt: 2 }}
                            >
                                {errorFile}
                            </MKTypography>}
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <MKBox>
                                        <TextField
                                            required
                                            sx={{
                                                mt: 5,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                            id="outlined-required"
                                            type="text"
                                            label={t("Register.card.name")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                        <TextField
                                            required
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="example@example.com"
                                            id="outlined-required"
                                            type="email"
                                            label={t("Register.card.email")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                        <TextField
                                            required
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={phone}
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                            }}
                                            id="outlined-required"
                                            type="number"
                                            label={t("Register.card.phone")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.phone}
                                            helperText={errors.phone}
                                        />
                                        <TextField
                                            required
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            placeholder="*********************"
                                            id="outlined-required"
                                            type={showPassword ? 'text' : 'password'}
                                            label={t("Register.card.password")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={showPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {!loadingR && <TextField
                                            id="outlined-required"
                                            select
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            label={t("FNTEC.select.2")}
                                            value={region}
                                            onChange={(e) => {
                                                setRegion(e.target.value)
                                            }}
                                            InputProps={{
                                                sx: { height: '50px' }, // Ajustez la hauteur ici
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                        >
                                            {regions?.data && regions?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </TextField>}
                                        <TextField
                                            required
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={niu}
                                            onChange={(e) => {
                                                setNiu(e.target.value);
                                            }}
                                            placeholder="JNDOISDWEN832948"
                                            id="outlined-required"
                                            label={t("Register.card.niu")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.niu}
                                            helperText={errors.niu}
                                        />
                                        {/* <MKTypography
                                        variant="subtitle2"
                                        color="white"
                                    >
                                        {t("Register.card.attestation")}
                                    </MKTypography> */}
                                        <TextField
                                            required
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            onChange={handleFileChange}
                                            type="file"
                                            id="outlined-required"
                                            label={t("Register.card.attestation")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.attestation}
                                            helperText={errors.attestation}
                                        />
                                        <MKButton
                                            disabled={loading}
                                            variant="contained"
                                            color="white"
                                            size="large"
                                            component="a"
                                            onClick={handleSubmit}
                                            sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                        >
                                            {loading ? t("Register.card.loading") : t("Register.card.create")}
                                        </MKButton>
                                        <Link style={{ textDecoration: "none" }} to="/login">
                                            <MKTypography
                                                mt={3}
                                                variant="subtitle2"
                                                color="white"
                                            >
                                                {t("Register.card.already")} {t("Navbar.Login")}
                                            </MKTypography>
                                        </Link>
                                    </MKBox>
                                </form>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </MKBox>
    )
}

export default Register;