import { AppBar, Box, Container, Grid, Tab, Tabs, useTheme } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";
// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import React from "react";
import Employees from "./Employees";
import Permit from "./Permit";
import Requests from "./Requests";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Declaration() {
    const { t } = useTranslation();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MKBox sx={{ background: "#fff" }}>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left", mt: 10 }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Declaration.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <Grid mt={5} className="d-flex justify-content-center">
                    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={t("Declaration.Employees.title")} {...a11yProps(0)} />
                                <Tab label={t("Declaration.Permit.title")} {...a11yProps(1)} />
                                <Tab label={t("Declaration.Request.title")} {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <Employees value={value} index={0} dir={theme.direction}></Employees>
                        <Permit value={value} index={1} dir={theme.direction}></Permit>
                        <Requests value={value} index={2} dir={theme.direction}></Requests>
                    </Box>
                </Grid>
            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </MKBox>
    )
}

export default Declaration;